.main-padding {
    padding-block: 42px;
}

.dashboard-info {
    color: gray;
    margin-bottom: 40px;
}

.form-select {
    &.dashboard {
        background-color: $c-blue;
        color: $c-white;
        border-radius: 6px;
        background-image: url('../../images/caret-down.svg');

        option {
            background-color: $c-white;
            color: $c-text-primary;
        }
    }
}

.form-control {
    &.dashboard {
        cursor: pointer;

        &:focus {
            box-shadow: 0 0 0 0.25rem $c-blue-light;
        }
    }
}

.relative {
    position: relative;

    .date-picker {
        position: absolute;
        z-index: 999;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    &:has(.multi) {
        .date-picker {
            right: 24px;
        }
    }
}

.arl-table-simple {
    tbody > tr:nth-child(even) {
        background-color: $c-white;
    }

    td:first-child {
        word-break: break-all;
    }
}

.dashboard {
    &--detail {
        form {
            row-gap: 16px;
        }
    }
}

.login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    h3 {
        font-family: "Montserrat", sans-serif;
    }
}

