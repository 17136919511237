/*
    Declare variables here to use throughout the entire website like:
    - fonts
    - colors
    - spacings
    - sizings
*/

// Fonts
$font-regular: 'Inter', sans-serif;
$font-heading: 'Montserrat', sans-serif;

$font-size-small: 14px;
$font-size-menu-mobile: 36px;

// Colors

$c-primary: rgb(23, 23, 23);
$c-red: #bb4f4f;
$c-red-dark: #ff3b30;
$c-green: #4fbb5a;
$c-green-dark: #28a745;
$c-blue: #0d72ea;
$c-blue-light: #c2dbfe;
$c-blue-light-2: #c7ddf2;

$c-white: #fff;
$c-gray: #efefef;
$c-gray-2: #f2f2f7;
$c-gray-dark: #cecece;

$c-text-primary: #00163c;
$c-heading-primary: #343a40;

// sizing
$height-header: 96px;

// Etc...
// $font-size-root: 16px;

// breakpoints

$breakpoint-lg: 992px;
