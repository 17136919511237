@use 'sass:math';

@import '../../scss/abstracts/variables';

.arl-header {
    display: flex;
    min-height: $height-header;
    background-color: $c-blue;
    align-items: center;
    justify-content: space-between;
    padding-inline: 48px;
    color: white;

    &__logo {
        height: math.div($height-header, 2);
        pointer-events: none;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__flex {
        display: flex;
        gap: 8px;
    }

    &__dashboard-link {
        display: flex;
        gap: 8px;
        text-decoration: none;
        color: unset;

        &:hover {
            color: unset;
        }
    }

    &__logout-link {
        display: flex;
        gap: 8px;


        &:hover {
            cursor: pointer;
        }
    }
}
