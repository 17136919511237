.arl-btn {
    &__close {
        box-sizing: content-box;
        // width: 1em;
        // height: 1em;
        padding: 0.25em 0.25em;
        color: #000;
        background: transparent;
        border: 0;
        border-radius: 0.25rem;
        // opacity: 0.5;
    }

    &__full-width {
        width: 100%;
    }

    &__login {
        background-color: $c-blue;
        color: $c-white;

        &:hover {
            color: $c-white;
            background-color: #286090;
        }
    }
}
