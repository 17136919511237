// Regular
@mixin base-regular-font {
    font-family: $font-regular;
    font-style: normal;
}

@mixin font-regular {
    @include base-regular-font;
    font-weight: 400;
}

// Heading
@mixin base-heading-font {
    font-family: $font-heading;
    font-style: normal;
    color: $c-heading-primary;
}

@mixin font-heading {
    @include base-heading-font;
}

@mixin text-body {
    @include font-regular;
    font-size: 20px;
    line-height: 1.6;
}
