$border-color: grey;

.arl-table {
    width: 100%;

    &-simple {
    }

    &-expandable {
        .expandable-area {
            padding: 0 !important;
            background-color: $c-blue-light-2;

            tr:nth-child(even) {
                background-color: $c-white;
            }
        }
    }

    & td,
    th {
        padding: 0.75rem;

        a {
            text-decoration: none;
        }

        & > span {
            display: flex;
        }
    }

    .not-expanded {
        border: 2px solid transparent;
    }

    .expanded {
        border: 2px solid $c-blue-light-2;
    }

    &__dashboard,
    &__dashboard-items {
        thead {
            border-radius: 10px;

            th:first-child {
                padding-left: 20px;
            }

            th:last-child {
                padding-right: 20px;
                text-align: center;
            }
        }

        tbody {
            border-radius: 10px;

            &.expanded {
                .static-row {
                    background-color: $c-blue-light-2;
                }
            }

            tbody {
                box-shadow: 0 0 0 0 $border-color;
            }

            &:nth-child(odd) {
                background-color: $c-gray-2;
            }

            td:first-child {
                padding-left: 20px;
            }

            td:last-child {
                padding-right: 20px;

                & > span {
                    margin: auto;
                }
            }
        }


        &-items {
            tbody {
                tr > td:not(.no-logs):last-child {
                    display: none;
                }

                tr > td:has(.arl-icon-status) {
                    padding-right: 20px;

                    .arl-icon-status {
                        margin: 0 auto;
                    }
                }
            }
        }

        .col--0 {
            width: 180px;
        }
        .col--1 {
            width: 240px;
        }
        .col--2 {
            width: 140px;
        }
        .col--3 {
            width: 140px;
        }
        .col--4 {
            width: 140px;
        }
        .col--5 {
            width: 240px;
        }
        .col--6 {
            width: 140px;
        }
        .col--last {
            width: 60px;
            text-align: right;
        }
    }
}