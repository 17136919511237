@import '../../scss/abstracts/variables';

$icon-size: 20px;

.arl-icon-status {
    display: inline-block;
    width: $icon-size;
    height: $icon-size;
    border-radius: 50%;

    &.badge {
        width: fit-content;
        padding: 2px 10px;
        height: initial;
        justify-content: center;
        color: $c-white;
        border-radius: 20px;
        font-size: small;
    }

    &--failed {
        background-color: $c-red-dark;
    }
    &--ok {
        background-color: $c-green-dark;
    }
}
