@import '../abstracts/variables';
body {
    margin: 0;
    font-family: $font-regular;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.btn:focus {
    box-shadow: none;
}

footer {
    min-height: 16em;
    display: block;
}

.react-datepicker__close-icon {
    display: none;
}
