@import '../abstracts/mixins.typography';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$heading-sizes: (
    'h1': 40px,
    'h2': 32px,
    'h3': 28px,
    'h4': 24px,
    'h5': 20px,
    'h6': 18px,
);

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    @include font-heading;
    line-height: 1.6;
    margin: 0;
}

h1,
.h1 {
    font-weight: 700;
    font-size: map-get($heading-sizes, 'h1');
    letter-spacing: 0.2px;
}

h2,
.h2 {
    font-weight: 700;
    font-size: map-get($heading-sizes, 'h2');
    letter-spacing: 0.2px;
}

h3,
.h3 {
    font-weight: 700;
    font-size: map-get($heading-sizes, 'h3');
    letter-spacing: 0.1px;
}

h4,
.h4 {
    font-weight: 400;
    font-size: map-get($heading-sizes, 'h4');
    letter-spacing: 0.2px;
}

h5,
.h5 {
    font-weight: 400;
    font-size: map-get($heading-sizes, 'h5');
    letter-spacing: 0.1px;
}

h6,
.h6 {
    font-weight: 400;
    font-size: map-get($heading-sizes, 'h6');
    letter-spacing: 0.1px;
}
