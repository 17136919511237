@import 'react-tabs/style/react-tabs.scss';

.react-tabs {
    &__tab {
        font-weight: 800;
        &--selected {
            border-color: white;
            text-decoration: underline;
            text-underline-offset: 4px;
        }
        &-list {
            border-color: white;
            display: flex;
            justify-content: flex-end !important;
        }

        &-panel {
        }
    }
}
